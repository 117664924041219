import PropTypes from "prop-types";
import styles from "./SelectActivity1.module.css";
import { useNavigate } from "react-router";
import { observable } from "mobx";
import { observer } from "mobx-react"
import companyStore from "../CompanyStore";

const SelectActivity1 = observer(({ isVisible, onclose, escrowId, escrowName, className = "" }) => {
  const navigate = useNavigate();

  const handleCompanyKYCList = () => {
    companyStore.setEscrowId(escrowId);
    companyStore.setEscrowName(escrowName);
    navigate(`company`)
  };

  const handleCompanyEsignList = () => {
    companyStore.setEscrowId(escrowId);
    companyStore.setEscrowName(escrowName);
    navigate(`esign`)
  };

  const handleCompanyContractList = () => {
    companyStore.setEscrowId(escrowId);
    companyStore.setEscrowName(escrowName);
    navigate(`contract`)
  };

  if (!isVisible) return null;
  return (
    <div className={styles.selectActivityContainer}>
      <div className={[styles.selectActivity, className].join(" ")}>
        <div className={styles.selectActivityinner}>
          <div className={styles.selectActivityParent}>
            <div className={styles.selectActivity1}>Select Activity</div>
            <button className={styles.xCircle} onClick={onclose}>
              <img className={styles.icon} alt="" src="/icon.svg" />
            </button>
          </div>

          <div className={styles.selectActivity2}>

            <button className={styles.kycListWrapperbtn3}
              onClick={() => { handleCompanyKYCList() }}
            >
              <img className={styles.scanIcon} alt="" src="/scan31.svg" />
              <div className={styles.kycList11}>KYC List</div>
            </button>


            <button className={styles.kycListWrapperbtn1} id="Statement_Button"
              onClick={() => { handleCompanyContractList() }}>
              <img
                className={styles.agreementIcon}
                alt=""
                src="/agreement1.svg"
              />
              <div className={styles.kycList12}>Contracts</div>
            </button>



            <button className={styles.kycListWrapperbtn21} id="Transaction_Button"
              onClick={() => { handleCompanyEsignList() }}
            >
              <img
                className={styles.agreementIcon}
                alt=""
                src="/contract2.svg"
              />
              <div className={styles.kycList13}>E-sign & E-stamp</div>
            </button>

          </div>
        </div>
      </div>
    </div>
  );
});

SelectActivity1.propTypes = {
  className: PropTypes.string,
};

export default SelectActivity1;
