import PropTypes from "prop-types";
import styles from "./DirectorRejected.module.css";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import config from "../../../../../service/config";
import { useState } from "react";
import companyStore from "../../../CompanyStore";
const DirectorRejected = ({ isVisible,onclose, className = "" }) => {
  
  const navigate=useNavigate()
  const {id}=useParams()
  const kycid=companyStore.Dirct_KYC_id
  const compid =companyStore.companyID
  const [reason,setReason] =useState("")
  const handleReject=()=>{
  let obj={
    kyc_id:kycid,
    status:"rejected",
    reason:reason
  }
  axios.post(`${config.node_api_endpoint}/v1/director/kyc_request/update_status_demo_fe`,obj)
    .then(()=>{
      console.log("Director Status Rejected");
      navigate(`/content/company/rejectedkyc`)
      onclose()
    })
    .catch((er)=>{
      console.log(er)
      onclose()
    })
  }
  if(!isVisible) return null 
  return (
    <div className={[styles.addCompany, className].join(" ")}>
      <div className={styles.pleaseMentionReason}>
        Please, Mention reason for rejection?
      </div>
      <div className={styles.mobileMenu}>
        {/* <div className={styles.field}>
          <div className={styles.content}>
            <div className={styles.input}>
              <div className={styles.input1}>Write reason for reject...</div>
            </div>
          </div>
        </div> */}
        <textarea placeholder="Write reason for reject..." value={reason} onChange={(e)=>{setReason(e.target.value)}} className={styles.field}/>
      </div>
      <div className={styles.addCompanyInner}>
        <div className={styles.loadsButtonsParent}>
          <div className={styles.loadsButtons} onClick={()=>{
            onclose()
          }}>
            <b className={styles.loads}>Cancel</b>
          </div>
          <div className={styles.loadsButtons1} onClick={()=>{
            handleReject()
          }}>
            <b className={styles.loads}>Reject</b>
          </div>
        </div>
      </div>
    </div>
  );
};

DirectorRejected.propTypes = {
  className: PropTypes.string,
};

export default DirectorRejected;
