import PropTypes from "prop-types";
import styles from "./CompanyRejected.module.css";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import config from "../../../../../service/config";
import { useState } from "react";
import companyStore from "../../../CompanyStore";
const CompanyRejected = ({ isVisible, onclose, className = "" }) => {
  const navigate=useNavigate();
  const [reason,setreason] =useState("")
  const compID= companyStore.companyID
  const onReject =()=>{
    let obj={
      kyb_id:companyStore.KYBId,
      status:"rejected",
      reason:reason
    }
      axios.post(`${config.node_api_endpoint}/v1/company/kyb/update_status`,obj)
      .then((res)=>{
        console.log("Request has been rejected");
      navigate(`/content/company/reject`)
      })
      .catch((err)=>{console.log(err);
    
      })
      onclose()
    }
  if(!isVisible) return null
  return (
    <div className={[styles.addCompany, className].join(" ")}>
      <div className={styles.pleaseMentionReason}>
        Please, Mention reason for reject?
      </div>
      <div className={styles.mobileMenu}>
        
        <textarea className={styles.field} placeholder="Reason" value={reason} onChange={(e)=>{setreason(e.target.value)}}/>
      </div>
      <div className={styles.addCompanyInner}>
        <div className={styles.loadsButtonsParent}>
          <div className={styles.loadsButtons} onClick={onclose}>
            <b className={styles.loads}>Cancel</b>
          </div>
          <div className={styles.loadsButtons1}  onClick={()=>{onReject()}}> 
            <b className={styles.loads}>Reject</b>
          </div>
          
        </div>
      </div>
    </div>
  );
};

CompanyRejected.propTypes = {
  className: PropTypes.string,
};

export default CompanyRejected;
