import PropTypes from "prop-types";
import styles from "./ContractMain.module.css";
import { Fragment, useEffect, useState } from "react";
import Axios from  "axios"
import config from "../../../../service/config"
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from "react-router";

const ContractMain = ({ className = "" }) => {
  const [data,setData] =useState([])
  const navigate=useNavigate()
  const [showModel,setShowModel]=useState(false)
  const [currentcompid, setCurrentCompId] =useState("")
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [  currentCompName,setCurrentCompName]=useState("")
  const [filteredData, setFilteredData] = useState([]);

  
  useEffect(()=>{
    console.log("connfig" , config.node_api_endpoint);
    let obj ={
        "escrow_id":2321323123
    }
    Axios.post(`${config.mock_api_endpoint}/v1/contract/getlist`,obj)
    .then((res)=>{
      setData(res.data)
      console.log(res.data);
    })
    .catch((er)=>{
      console.log(er);
    })
  },[showModel])
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };

  const resetFilters= () =>{
    setFilteredData(data)
  }

  const applyFilters = () => {
    let filtered = data;

    // if (statusFilter) {
    //   filtered = filtered.filter((item) => item.status === statusFilter);
    // }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => new Date(item.created_at).toDateString() === new Date(createdDateFilter).toDateString());
    }
    
    // if (textFilter) {
    //   filtered = filtered.filter((item) => item.status === textFilter);
    // }

    setFilteredData(filtered);
  };
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
     
     <div className={styles.newCompany_div}>
        <button onClick={()=>{navigate("/content/contract/newcontract")
        }} className={styles.newCompany}>
        <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
        <b className={styles.addNewCompany}>Add Contract</b>
        </button>
      </div>

      <div className={styles.searchFiltersParent}>
        <div className={styles.frameParent}>


            {/* MUI search for table */}
          <div className={styles.leadingIconParent}>
            <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
            <TextField label="Search" variant="standard" value={textFilter} onChange={(e) => { setTextFilter(e.target.value) }} />
             </div>
          
          
          <div className={styles.mobileMenuParent}>
              {/* mui date and time */}
            {/* <div className={styles.mobileMenu}>
              <div className={styles.field}>
                <div className={styles.content}>
                  <div className={styles.floatingLabel}>
                    <div className={styles.label}>{`Created date `}</div>
                  </div>
                  <div className={styles.input}>
                    <div className={styles.input1}>28/08/2024</div>
                    <div className={styles.input1}>12:59AM</div>
                    <img
                      className={styles.cursorIcon}
                      alt=""
                      src="/cursor.svg"
                    />
                  </div>
                </div>
              </div>
              <img
                className={styles.mobileMenuChild}
                alt=""
                src="/trailingicon-2.svg"
              />
            </div> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
                  />
                </LocalizationProvider>

              {/* mui filter for table */}
            {/* <div className={styles.mobileMenu}>
              <div className={styles.field}>
                <div className={styles.content}>
                  <div className={styles.floatingLabel}>
                    <div className={styles.label}>Status</div>
                  </div>
                  <div className={styles.input3}>
                    <div className={styles.input1}>Requested</div>
                    <img
                      className={styles.cursorIcon}
                      alt=""
                      src="/boxarrowindown3.svg"
                    />
                  </div>
                </div>
              </div>
              <img className={styles.mobileMenuChild} alt="hey" src="/arrow14.svg" />
            </div> */}
            


              {/* button to apply filter to table */}
            <div className={styles.loadsButtons1} onClick={applyFilters}>
              <img
                className={styles.boxArrowInDownIcon}
                alt=""
                src="/boxarrowindown1.svg"
              />
              <b className={styles.addNewCompany}>Apply</b>
            </div>

          </div>

          {/* Frame Container ends */}
        </div>
      </div>
      
      <div className={styles.frameGroup}>
        <table className={styles.table}>
          <thead className={styles.theadcontain}>
            <tr className={styles.trheaderContent}>
              <th>Created Data</th>
              <th>Contract Name</th>
              <th>Status</th>
              <th>Action</th>
              </tr>
          </thead>
          <tbody className={styles.tbodycontain}>
          {data && data.slice().reverse().map((detail) => {
  return (
    <tr key={detail._id}>
      <td>{detail.CreatedDate}</td>
      <td>{detail.ContractName}</td>
      <td>{detail.Status}</td>
      <td>
        <button
          className={styles.change}
          onClick={() => {
            handleScrollToTop()
            setCurrentCompId(detail._id);
            setCurrentCompName(detail.name);
            console.log(detail._id);
            navigate("/content/company/kyclist/main")
          }}
        >
          View
        </button>
      </td>
      {/* `company/${detail.id}` */}
    </tr>
  );
})}

          </tbody>
        </table>
      
      </div>
    </div>
  );
};

ContractMain.propTypes = {
  className: PropTypes.string,
};

export default ContractMain;
