import PropTypes from "prop-types";
import styles from "./PageContainerComp.module.css";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import companyStore from "../../CompanyStore";
import AddNewKYC from "../AddKYC/AddNewKYC";
import {
  Pagination,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import config from "../../../../service/config";

const PageContainerComp = ({ className = "" }) => {
  const [sendRequest, setSendRequest] = useState(false);
  const [directorID, setDirectorID] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [data, setData] = useState([]);
  const [compName, setCompName] = useState("");
  const [check, setCheck] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  let [id,setId]=useState("")
  
  const navigate = useNavigate();
 useEffect(()=>{
  setId(companyStore.companyID)
  setCompName(companyStore.companyName)
 },[])
    
  useEffect(() => { 
    axios.get(`${config.node_api_endpoint}/v1/company/kyc_list?company_id=${companyStore.companyID}`)
      .then((res) => {
        setData(res.data);
        console.log("result Data",res.data);
        
        setFilteredData(res.data); // Initialize filtered data with full data
        res.data.forEach((detail) => {
          if (detail.kyc_type === "Company KYC" && (detail.status === "pending_approval" || detail.status === "approved")) {
            setCheck(true);
          }
        });
      })
      .catch((er) => {
        console.log(er);
      });
  }, [showModel]);

  const handleClick = (para) => {
    if (para[0] === "Company KYC") {
      companyStore.setKYBId(para[1])
      navigate(`/content/company/companykyc/detail`);
    } else if (para[0] === "Director KYC") {
      companyStore.setDirect_KYC_ID(para[1])
      navigate(`/content/company/directkyc/detail`);
      
    }
  };

  const resetFilters= () =>{
    setFilteredData(data)
  }

  const applyFilters = () => {
    let filtered = data;

    if (statusFilter) {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => new Date(item.created_at).toDateString() === new Date(createdDateFilter).toDateString());
    }
    
    if (textFilter) {
      filtered = filtered.filter((item) => item.status === textFilter);
    }

    setFilteredData(filtered);
  };

  return (
    <Fragment>
      <div className={[styles.pageContainer, className].join(" ")}>
        <div className={styles.frameParent}>
          <div className={styles.escrowstackPrivateLimitedParent}>
            <b className={styles.escrowstackPrivateLimited}>{compName}</b>
            <button className={styles.loadsButtons} onClick={() => setShowModel(true)}>
              <img className={styles.plusCircleIcon} alt="" src="/pluscircle2.svg" />
              <b className={styles.newKycRequest}>New KYC Request</b>
            </button>
          </div>

          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              {/* Filter inputs */}
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <img className={styles.leadingIcon} alt="" src="/leadingicon3.svg" />
                  <TextField label="Search" variant="standard" value={textFilter} onChange={(e) => { setTextFilter(e.target.value) }} />
                </div>
              </div>

              <div className={styles.mobileMenuParent}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
                  />
                </LocalizationProvider>

                <FormControl sx={{ width: "250px" }}>
                  <InputLabel id="escrow-select-label">Status</InputLabel>
                  <Select
                    labelId="escrow-select-label"
                    id="escrow-select"
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => setStatusFilter(e.target.value)}
                    sx={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"requested"}>Requested</MenuItem>
                    <MenuItem value={"pending_approval"}>Pending Approval</MenuItem>
                    <MenuItem value={"approved"}>Approved</MenuItem>
                    <MenuItem value={"rejected"}>Rejected</MenuItem>
                  </Select>
                </FormControl>

                {/* Button to apply filter */}
                <div className={styles.loadsButtons1} onClick={applyFilters}>
                  <b className={styles.escrowstackPrivateLimited}>Apply</b>
                </div>
              </div>
            </div>
          </div>

          {/* MUI Table */}
          <TableContainer component={Paper} className={styles.table_div}>
            <Table>
              <TableHead className={styles.theadcontain}>
                <TableRow className={styles.trheaderContent}>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Name of Company / Director</TableCell>
                  <TableCell>KYC Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.tbodycontain}>
                {filteredData.map((detail, index) => (
                  <TableRow key={index}>
                    <TableCell>{detail.created_at}</TableCell>
                    <TableCell>{detail.name}</TableCell>
                    <TableCell>{detail.kyc_type}</TableCell>
                    <TableCell>{detail.status.toUpperCase()}</TableCell>
                    <TableCell className={styles.containerbtn}>
                      {detail.kyc_type === "Company KYC" && (
                        <button
                          className={styles.btn}
                          onClick={() => handleClick([detail.kyc_type, detail.kyb_id])}
                        >
                          <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                        </button>
                      )}

                      {detail.kyc_type === "Director KYC" && detail.status !== "requested" && (
                        <button
                          className={styles.btn}
                          onClick={() => handleClick([detail.kyc_type, detail.kyc_id])}
                        >
                          <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                        </button>
                      )}
                      {detail.kyc_type === "Director KYC" && detail.status === "requested" && (
                        <button className={styles.btndis}>
                          <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination />
        </div>
      </div>
      <AddNewKYC isVisible={showModel} onClose={() => {setShowModel(false)}} check={check} />
    </Fragment>
  );
};

PageContainerComp.propTypes = {
  className: PropTypes.string,
};

export default PageContainerComp;
