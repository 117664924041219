import React, { useEffect } from 'react'
import Contractview from './AdminView/Contractview'
import styles from "./ContractList.module.css"
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import ContractMain from './ContractMain/ContractMain'
import NewContract from './NewContract/NewContract'
export default function ContractList() {
  
  const navigate=useNavigate()
  const location = useLocation();
  useEffect(()=>{
    if(location.pathname==="/content/contract"){
      navigate('main')
    }
      
  },[])
  return (
    // <div className={styles.contract}>
    //  <Contractview/>
    // </div>
    <Routes>
      <Route path="main" element={<ContractMain/>}/>
      <Route path="newcontract" element={<NewContract/>}/>
    </Routes>
  )
}
