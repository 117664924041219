import PropTypes from "prop-types";
import styles from "./AddNewKYC.module.css";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../service/config";
const AddNewKYC = ({ isVisible, onClose ,check, className = "" }) => {
  // const [check,setCheck]=useState(true)
  const navigate=useNavigate()
  const {id}=useParams()


  
  if(!isVisible){return null;}
  return (
    <div className={styles.modlestyle}>
    <div className={[styles.xCircleParent, className].join(" ")}>
      <img className={styles.xCircleIcon} alt="" src="/xcircle.svg" onClick={()=>{onClose()}}/>
      <div className={styles.frameParent}>
        {check &&//disables company button
        <div className={styles.building07Parentdis} >
        <img
          className={styles.building07Icondis}
          alt=""
          src="/building07.svg" 
        />
        <b className={styles.companydis}>Company</b>
      </div>}
      
  {!check&&//enables company button
        <div className={styles.building07Parent} onClick={()=>{navigate(`/content/company/newcompanykyc`)}}>
          <img
            className={styles.building07Icon}
            alt=""
            src="/building07.svg" 
          />
          <b className={styles.company}>Company</b>
        </div>}
        <div className={styles.vectorParent} onClick={()=>{navigate(`/content/company/director`)}}>
          <img className={styles.vectorIcon} alt="" src="/Vector.svg" />
          <b className={styles.company}>Director</b>
        </div>
      </div>
    </div>
    </div>
  );
};

AddNewKYC.propTypes = {
  className: PropTypes.string,
};

export default AddNewKYC;
