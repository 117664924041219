import PropTypes from "prop-types";
import styles from "./CompanyApproved.module.css";
import { useLocation, useNavigate, useParams } from "react-router";
import usePagination from "@mui/material/usePagination/usePagination";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";

const CompanyApproved = ({ isVisible, onclose, className = "" }) => {
const navigate=useNavigate();
const compID= companyStore.companyID
// console.log("approval ",id.split("_")[1]);

const onApproval =()=>{
let obj={
  kyb_id:companyStore.KYBId,
  status:"approved"
}
  axios.post(`${config.node_api_endpoint}/v1/company/kyb/update_status`,obj)
  .then((res)=>{
    console.log("Request has been approved");
  navigate(`/content/company/route`)
  })
  .catch((err)=>{console.log(err);

  })
  onclose()
}

  if(!isVisible) return null
  return (
    <div className={[styles.addCompany, className].join(" ")}>
      <div className={styles.areYouSure}>
        Are you sure you want to approve the actions?
      </div>
      <img className={styles.icon} alt="" src="/4019677-2131040-1.svg" />
      <div className={styles.addCompanyInner}>
        <div className={styles.loadsButtonsParent}>
          <div className={styles.loadsButtons} onClick={onclose}>
            <b className={styles.loads}>No</b>
          </div>
          <div className={styles.loadsButtons1} onClick={onApproval}>
            <b className={styles.loads}>Yes</b>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyApproved.propTypes = {
  className: PropTypes.string,
};

export default CompanyApproved;
