import PropTypes from "prop-types";
import styles from "./RequestEsignGenerated.module.css";
import Lottie from "lottie-react"
import approved from "./Animation-GeneratedRequestGIF.json"
import { useNavigate, useParams } from "react-router";

const RequestGenerated = ({ isVisible,onclose,className = "" }) => {
  const navigate=useNavigate()
  const {id} =useParams()
  console.log("inRequest");
  
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.httpslottiefilescomanimatParent}>
      <Lottie
          className={styles.httpslottiefilescomanimatIcon}
          animationData={approved}
          loop={true}
        />
        <div className={styles.successfullyGeneratedRequest}>
          Successfully Sent a E-sign Request
        </div>
        <button className={styles.returnToKycList} onClick={()=>{navigate(`/content/esign/esign/main`)}}>
          <b className={styles.returnToKyc}>Return to E-sign List</b>
        </button>
      </div>
    </div>
  );
};

RequestGenerated.propTypes = {
  className: PropTypes.string,
};

export default RequestGenerated;


// ApprovedPOPup