import { useCallback } from "react";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./CompanyKYC.module.css";import { useEffect, useState } from "react";
import axios from "axios";
import Lottie from "lottie-react"
import load from "./LoadGIF.json"
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";

const CompanyKYC = ({  className = "" }) => {
  
  const [compName,setcompName] =useState("")
  const [POCname,setPOCName] =useState("")
  const [email,setEmail] =useState("")
  const [data,setData]= useState("")
  const [remark,setRemark] =useState("")
  const [id,setID]=useState("")
  const [Name, setName] = useState("");
  const [len,setLen]=useState()
  const [clicked,setClicked] =useState(false)

  const navigate = useNavigate();
useEffect(()=>{
  setID(companyStore.companyID)
  setName(companyStore.companyName)
},[])

  // useEffect(()=>{
  //   axios.get(`http://localhost:5000/companies/${id}`)
  //   .then((res)=>{
  //     setcompName(res.data.NameOfCompany)
  //     setLen((res.data.KYC.length)+1);
  //     setData(res.data)
  //   })
  // },[])
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: smooth scrolling
    });
  };
  const handleClick=()=>{
    if(compName==""||email==""||POCname==""){
      alert("All Fields Are Required")
      return 
    }
    setClicked(true);
    const obj={
          company_id:id,
          company_name:compName,
          authorized_signatory_email:email,
          authorized_signatory:POCname,
          remark:remark
    }
    
    axios.post(`${config.node_api_endpoint}/v2/company/generate_kyb_request`,obj)
    .then(()=>{console.log("Data has been Send");
      handleScrollToTop()
  navigate(`/content/company/kyc_request`)
  setcompName("")
  setEmail("")
  setPOCName("")
    })
    .catch((err)=>{
      console.log(err);
      
    })
    
  
}

const clearAll=()=>{
  setcompName("")
  setEmail("")
  setPOCName("")
}
const handlenavigate=()=>{
  navigate(`/content/company/kyclist/main`)
}
// if(!isVisibleComp) return null;
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      
      <div className={styles.frameParent}>
        <button
          className={styles.arrowNarrowLeftParent}
          // onClick={}
          onClick={handlenavigate}
        >
          <img
            className={styles.arrowNarrowLeftIcon}
            alt=""
            src="/arrownarrowleft1.svg"
          />
          <div className={styles.back} >Back</div>
        </button>
        <div className={styles.escrowstackPrivateLimitedWrapper}>
          <b className={styles.escrowstackPrivateLimited}>
            {Name}
          </b>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.companyKycParent}>
            <div className={styles.companyKyc}>Company KYC</div>
            <button className={styles.input} onClick={clearAll}>Clear all</button>
          </div>
          <div className={styles.mobileMenuParent}>
            <TextField
              className={styles.mobileMenu}
              color="primary"
              label="Company Name"
              variant="outlined"
              value={compName}     
              onChange={(e)=>{setcompName(e.target.value)}}     
              sx={{ "& .MuiInputBase-root": { height: "56px" } }}
            />
            <div className={styles.mobileMenuGroup}>
              <TextField
                className={styles.mobileMenu1}
                color="primary"
                label="Authorized Name"
                variant="outlined"
                value={POCname}  
                onChange={(e)=>{setPOCName(e.target.value)}}
                sx={{ "& .MuiInputBase-root": { height: "56px" } }}
              />
              <div className={styles.mobileMenu2}>
                <TextField
                  className={styles.field}
                  color="primary"
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                  label="Email ID"
                  variant="outlined"
                  type="email"
                  sx={{
                    "& .MuiInputBase-root": { height: "56px" },
                    width: "344px",
                  }}
                />
              </div>
            </div>

            {/* <div className={styles.mobileMenuGroup}>
              <TextField
                className={styles.mobileMenu1}
                color="primary"
                label="CIN Number"
                variant="outlined"                
                value={Cin} 
                onChange={(e)=>{setCin(e.target.value)}}
                sx={{ "& .MuiInputBase-root": { height: "56px" } }}
              />
              <div className={styles.mobileMenu2}>
                <TextField
                  className={styles.field}
                  color="primary"
                  value={Gst}
                  onChange={(e)=>{setGst(e.target.value)}}
                  label="GST Number"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": { height: "56px" },
                    width: "344px",
                  }}
                />
              </div>
            </div>
            <TextField
              className={styles.mobileMenu}
              color="primary"
              label="Company PAN"
              variant="outlined"
              value={Pan}     
              onChange={(e)=>{setPan(e.target.value)}}     
              sx={{ "& .MuiInputBase-root": { height: "56px" } }}
            /> */}


            <div className={styles.mobileMenu3}>
              <textarea
                className={styles.field1}
                placeholder="Remark"
                type="text"
                value={remark}
                onChange={(e)=>{setRemark(e.target.value)}}
              />
            </div>
          </div>
          <button className={styles.loadsButtons} onClick={()=>{handleClick()}}>
          {clicked &&
          <Lottie
          className={styles.httpslottiefilescomanimatIcon}
          animationData={load}
          loop={true}
        /> } 

            <b className={styles.generateRequest}>Generate Request</b>
          </button>
        </div>
      </div>
    </div>
  );
};

CompanyKYC.propTypes = {
  className: PropTypes.string,
};

export default CompanyKYC;
