// Content.js
import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom'; // Fix import here
import SideNavContainer from "../sidenav/SideNavContainer";
import styles from "./Content.module.css";


const Content = () => {
  const navigate=useNavigate()
  
  const auth = localStorage.getItem('authToken')
    console.log("authentication",auth);
    useEffect(()=>{
      if(auth){

      }else{
        navigate("/")
      }
    },[])
   
  return (
    <Fragment>
    <div className={styles.companyList}>
      <div className={styles.pagecontainer}>
        <SideNavContainer escrowstackLogos27="/escrowstack-logos-27@2x.png" />
        <div className={styles.rightofsidenavcontainer}>
            <Outlet/>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default Content;
