import PropTypes from "prop-types";
import styles from "./CompanyKYCRequestGenerated.module.css";
import { useNavigate, useParams } from "react-router";

const RequestGenerated = ({ className = "" }) => {
  const navigate=useNavigate()
  const {id} =useParams()
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.httpslottiefilescomanimatParent}>
      {/* <Lottie
          className={styles.httpslottiefilescomanimatIcon}
          animationData={approved}
          loop={true}
        /> */}
        <div className={styles.successfullyGeneratedRequest}>
          Company KYC Genrated Successfully
        </div>
        <button className={styles.returnToKycList} onClick={()=>{navigate(`/content/company/kyclist/main`)}}>
          <b className={styles.returnToKyc}>Return to KYC List</b>
        </button>
      </div>
    </div>
  );
};

RequestGenerated.propTypes = {
  className: PropTypes.string,
};

export default RequestGenerated;


// ApprovedPOPup