import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./CompanyKycDetail.module.css";
import { useNavigate, useParams } from "react-router";
import { Fragment, useState } from "react";
import CompanyApproved from "./CompanyPopupConfirmation/CompanyApproved"
import CompanyRejected from "./CompanyPopupConfirmation/CompanyRejected"
import axios from "axios";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";

const CompanyKycDetail = ({ className = "" }) => {
    const [showApprove,setShowApprove] =useState(false)
    const [showReject,setShowReject] =useState(false)
    const [data,setData] =useState([])
    const [isRejectVisible,setRejectVisible]= useState(false)
    
    const navigate=useNavigate()
  // const compId=id.split('_')[1];
  const onclose=()=>{
    setShowApprove(false)
    setShowReject(false)
  }
  const handlenavi=()=>{
  navigate(`/content/company/kyclist/main`)
  }
  const onFrameButtonClick = useCallback(() => {
    // Please sync "Company List & Director List" to the project
  }, []);

  const onLoadsButtonsClick = useCallback(() => {
    // Please sync "Reject Pop-up" to the project
  }, []);

  const onLoadsButtonsClick1 = useCallback(() => {
    // Please sync "Approve Pop-up" to the project
  }, []);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: smooth scrolling
    });
  };
  useEffect(()=>{
    console.log("kycID", companyStore.KYBId);
    
    axios.get(`${config.node_api_endpoint}/v1/company/kyb?id=${companyStore.KYBId}`)
    .then((res)=>{
      console.log(res.data[0]);
      setData(res.data[0])
      console.log(res.data[0].status);
      if(res.data[0].status=="pending_approval"){
        setRejectVisible(true)
      }
      
    }).catch((err)=>{
      console.log(err);
      
    })
  },[])
  // console.log("data",data.pan_info.full_name);


  function getAddressFromCINorGST(companyInfoJson){
    if(!companyInfoJson.cin_info){
      return 'Not Provided'
    }
   else if(companyInfoJson.cin_info["Registered Address"]){
      return formatAddress(companyInfoJson.cin_info['Registered Address'][companyInfoJson.cin_info['Registered Address'].length - 1])
    } 
   else if(!companyInfoJson.gst_info){
      return "Not Provided"
    }
   else if(companyInfoJson.gst_info.details.pradr.addr){
      var addr = companyInfoJson.gst_info.details.pradr.addr;
      // return `${addr.bno},${addr.st},${addr.loc},${addr.dst},${addr.stcd},${addr.pncd}`
    }
    return 'Not Provided'
  }
  function formatAddress(addressJson){
    var keys = Object.keys(addressJson);
    var addressStr = '';
    for(var i=0;i<keys.length-2;i++){
      if(addressJson[keys[i]]=='' || keys[i]=='activeStatus'||keys[i]=='Registered Address'){
        continue;
      }
      else{
      addressStr+=addressJson[keys[i]]+","
      }
    }
    return addressStr
  }

  function cinName(data){
    if(!data?.cin_info){
      return 'Not Provided';
    } else if(data?.cin_info["Company Name"]){
      return data.cin_info["Company Name"]
    } else {
      return 'ESCROW STACK PRIVATE LIMITED';
    }
  }

  return (
    <Fragment>
    <div className={[styles.pageContainer, className].join(" ")}>
     
      <div className={styles.frameParent}>
        <button
          className={styles.arrowNarrowLeftParent}
          onClick={()=>{handlenavi()}}
        >
          <img
            className={styles.arrowNarrowLeftIcon}
            alt=""
            src="/arrownarrowleft1.svg"
          />
          <div className={styles.back}>Back</div>
        </button>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.companyKycDetailsWrapper}>
              <div className={styles.companyKycDetails}>
                Company Kyc Details
              </div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.frameDiv}>
                <div className={styles.companyNameWrapper}>
                  <div className={styles.companyKycDetails}>Company Name</div>
                </div>
                <div className={styles.div}>:</div>
              </div>
              <div className={styles.escrowstackPrivateLimited}>
               {data?.original_data?.company_name|| "NA"}
              </div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.frameDiv}>
                <div className={styles.companyNameWrapper}>
                  <div className={styles.companyKycDetails}>POC Name</div>
                </div>
                <div className={styles.div}>:</div>
              </div>
              <div className={styles.escrowstackPrivateLimited}>{data?.original_data?.authorized_signatory|| "NA"}</div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.frameDiv}>
                <div className={styles.companyNameWrapper}>
                  <div className={styles.companyKycDetails}>Email ID</div>
                </div>
                <div className={styles.div}>:</div>
              </div>
              <div className={styles.escrowstackPrivateLimited}>
              {data?.original_data?.authorized_signatory_email|| "NA"}
              </div>
            </div>
            <div className={styles.frameDiv}>
              <div className={styles.frameDiv}>
                <div className={styles.companyNameWrapper}>
                  <div className={styles.companyKycDetails}>Remark</div>
                </div>
                <div className={styles.div}>:</div>
              </div>
              <div className={styles.escrowstackPrivateLimited}>{data?.original_data?.remark|| "NA"}</div>
            </div>
          </div>
          <div className={styles.frameParent8}>
            <div className={styles.companyKycResponseWrapper}>
              <b
                className={styles.companyKycResponse}
              >{`Company Kyc Response `}</b>
            </div>
            {/* ////////////////////////////////////////////////////////////////////////// */}
            <div className={styles.frameParent9}>


          {!isRejectVisible&& data.status==="rejected"  &&  
          <div className={styles.status}>
              <div className={styles.statusHeading}>  {data?.status?.toUpperCase()}  </div>
              <div>{data.reason}</div>
              </div>}

              { !isRejectVisible&& data.status==="approved" &&<div className={styles.statusapproved}>
              <div className={styles.statusHeading}>{data?.status?.toUpperCase()}</div>
              <div>{data.reason}</div>
              </div>}


              <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>PAN</div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>
                  {data?.PAN || "Not Provided"}
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>
                      Name as per PAN
                    </div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>

                <div className={styles.escrowstackPrivateLimited}>{data?.pan_info?.full_name || "Not Provided"}</div>
                
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>CIN</div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>
                  {data?.CIN || "Not Provided"}
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>
                      Name as per CIN
                    </div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>{cinName(data)}</div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>GST.NO</div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>
                  {data?.GST ||"Not Provided" }
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>
                      Name as per GST
                    </div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>{data?.gst_info?.corporate_name || "Not Provided"}</div>
              </div>
              {/* <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>Company Name</div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div
                  className={styles.escrowstackPrivateLimited}
                >{`Escrow stack Private Limited `}</div>
              </div> */}
              {/* <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>
                      Date of incorpation
                    </div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>
                  NA
                </div>
              </div> */}
              <div className={styles.frameDiv}>
                <div className={styles.frameDiv}>
                  <div className={styles.companyNameWrapper}>
                    <div className={styles.companyKycDetails}>
                     Registered Address


                    </div>
                  </div>
                  <div className={styles.div}>:</div>
                </div>
                <div className={styles.escrowstackPrivateLimited}>
                { 
                getAddressFromCINorGST(data)}
                </div>
              </div>
            </div>
            { isRejectVisible&& <div className={styles.frameDiv}>
              <button
                className={styles.loadsButtons}
                onClick={()=>{
                  handleScrollToTop()
                  setShowReject(true); setShowApprove(false)}}
              >
                <b className={styles.reject}>Reject</b>
              </button>
              <button
                className={styles.loadsButtons1}
                onClick={()=>{
                  handleScrollToTop()
                  setShowApprove(true); setShowReject(false)}}
              >
                <b className={styles.reject}>Approve</b>
              </button>
            </div>}
          </div>
        </div>
      </div>
      
      <div className={styles.spacing}></div>
    </div>
    <CompanyApproved isVisible={showApprove} onclose={onclose} />
  <CompanyRejected isVisible={showReject} onclose={onclose}/>
  </Fragment>
  );
};

CompanyKycDetail.propTypes = {
  className: PropTypes.string,
};

export default CompanyKycDetail;
