import React, { useCallback, useEffect, useState  } from "react";

import { MenuItem,TextField, Button,Select } from "@mui/material";
import { useNavigate, } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./NewContract.module.css";
import config from "../../../../service/config";
import Lottie from "lottie-react"
import load from "./LoadGIF.json"
import axios from "axios";
import {DateTimePicker, DateTimePickerTabs} from "@mui/x-date-pickers/DateTimePicker"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import companyStore from "../../CompanyStore";

const NewContract = ({ className = "" }) => {
  const navigate = useNavigate(); 
  const [pdf,setPdf] =useState("application/pdf")
  const [contractAdminName, setContractAdminName] = useState("");
  const [parties, setParties] = useState([{ name: "", email: "" ,companyname:"",role:""}]);
  const formdata=new FormData()
  const [agreementName, setAgreementName] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [clicked,setClicked] =useState(false)

  const onFrameContainerClick = useCallback(() => {
    navigate("/content/esign/esign/main")
  }, [navigate]);

  const handleAddParty = () => {
    setParties([...parties, { name: "", email: "" ,companyname:"",role:""}]);
  };

  const handleRemoveParty = (index) => {
    if (parties.length > 1) {
      setParties(parties.filter((_, i) => i !== index));
    }
  };
console.log("Parties",parties);

  const handleClearAll = () => {
    setAgreementName("");
    setExpiryDate(null);
    setParties([{ name: "", email: "" ,companyname:"",role:""}]);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
console.log("file",e.target.files[0]);
    if (file && file.type !== "application/pdf") {
      alert("Please upload only PDF files.");
      e.target.value = "";
      return
    }

      setPdf(file)
      // formdata.append('pdf_file', file)//appended the pdf file  to append filedata
    
  };
  const formatnewDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  };
  
  const handleContinue = () => {
    // Check if there is at least one party with valid name and email
    const isPartyValid = parties[0].name.trim() !== "" && parties[0].email.trim() !== ""&&agreementName !=="" &&expiryDate !== null;
    if(expiryDate<(new Date())){
      alert("Invalid Date")
      return;
    }
    if (!isPartyValid) {
      alert("All Fields are Required.");
      return;
    }
  
    if (pdf === "application/pdf") {
      alert("Add a PDF file.");
      return;
    }
  
    
  
    const date = `${expiryDate.$d}`;
    const FinalDate = formatnewDate(date);
  
    const filteredParties = parties.filter(
      (party) => party.name.trim() !== "" && party.email.trim() !== ""
    );
  
    setClicked(true);
    formdata.append("agreement_name", agreementName);
    formdata.append("company_id", companyStore.companyID);
    formdata.append("expiry_date", FinalDate);
    formdata.append("signing_parties", JSON.stringify(filteredParties));
    formdata.append("pdf_file", pdf, "[PROXY]");
  
    axios
      .post(`${config.node_api_endpoint}/v1/esign/upload`, formdata)
      .then((res) => {
        companyStore.setDoc_id(res.data._id)
        navigate(`/content/esign/signaggr`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  console.log(parties);
  
  return (
    <div className={[styles.frameParent, className].join(" ")}>
      <div className={styles.frameWrapper} onClick={onFrameContainerClick}>
        <div className={styles.arrowNarrowLeftParent}>
          <img
            className={styles.arrowNarrowLeftIcon}
            alt=""
            src="/arrownarrowleft.svg"
          />
          <div className={styles.back}>Back</div>
        </div>
      </div>
      <div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.frameContainer}>
          <div className={styles.uploadTheFinalDraftOfAgreParent}>
            <div className={styles.uploadTheFinal}>
              + Upload the agreement
            </div>
            <div className={styles.uploadTheFinal}>
              Upload only word Document
            </div>
          </div>
          <img
            className={styles.imagePlaceholderIcon}
            alt=""
            src="/image-placeholder.svg"
          />
          <button className={styles.groupParent}>
            <img className={styles.frameChild} alt="" src="/group-6.svg" />
            <div className={styles.dragDropContainer}>
              <span
                className={styles.dragDrop}
              >{`Drag & drop the file here or   `}</span>
              <input
                type="file"
                className={styles.fileInput}
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </div>
          </button>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.partiesParent}>
            <div className={styles.parties}>Enter Contract details</div>
            <div className={styles.input} onClick={handleClearAll}>
              Clear all
            </div>
          </div>
          <ol>
          <div className={styles.frameParent1}>
            
            <div className={styles.mobileMenuParent}>
              <TextField
                className={styles.mobileMenu}
                color="primary"
                label="Name of Contract"
                variant="outlined"
                value={agreementName}
                onChange={(e) => setAgreementName(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": { height: "56px",borderRadius:"12px" },
                  width: "309px",
                }}
              />
              <TextField
              className={styles.mobileMenu}
              color="primary"
              label="Contract Admin's name"
              variant="outlined"
              value={agreementName}
              onChange={(e) => setAgreementName(e.target.value)}
              sx={{
                "& .MuiInputBase-root": { height: "56px",borderRadius:"12px" },
                width: "309px",
              }}
            />
              {/* <input type="date" value={expiryDate} className={styles.mobileMenu1} onChange={(e)=>{
                console.log(expiryDate);
                setExpiryDate(e.target.value)}}/> */}
            </div>
          </div>
          {parties.map((party, index) => (
  <div key={index} className={styles.frameWrapper1}>
   <div className={styles.frame1}>
    
   <li className={styles.partieslistitem}>Counter Party</li>
   {index>=1 &&<Button
      className={styles.inputParent}
      onClick={() => handleRemoveParty(index + 2)}
    >
      <div className={styles.input1}>Remove</div>
      <img
        className={styles.arrowNarrowLeftIcon}
        alt=""
        src="/minuscircle.svg"
      />
    </Button>}
   </div>
   <div className={styles.mobileMenuParent1}>
    <div className={styles.mobileMenuParent}>
      <div className={styles.mobileMenu2}>
        <TextField
          className={styles.field}
          color="primary"
          label="Name"
          variant="outlined"
          value={party.name}
          onChange={(e) => {
            const newParties = [...parties];
            newParties[index].name = e.target.value; // Corrected index
            setParties(newParties);
          }}
          sx={{
            "& .MuiInputBase-root": { height: "56px",borderRadius:"12px" },
            width: "309px",
          }}
        />
        <img
          className={styles.trailingIcon2}
          alt=""
          src="/trailingicon-2.svg"
        />
      </div>
      <TextField
        className={styles.mobileMenu3}
        color="primary"
        label="Company Name"
        variant="outlined"
        value={party.companyname}
        onChange={(e) => {
          const newParties = [...parties];
          newParties[index].companyname = e.target.value; // Corrected index
          setParties(newParties);
        }}
        sx={{
          "& .MuiInputBase-root": { height: "56px",borderRadius:"12px" },
          width: "309px",
        }}
      />
      
    </div>
    <div  className={styles.mobileMenuParent}>
    <TextField
        className={styles.mobileMenu3}
        color="primary"
        label="Email ID"
        variant="outlined"
        type="email"
        value={party.email}
        onChange={(e) => {
          const newParties = [...parties];
          newParties[index].email = e.target.value; // Corrected index
          setParties(newParties);
        }}
        sx={{
          "& .MuiInputBase-root": { height: "56px",borderRadius:"12px" },
          width: "309px"
        }}
      />
          {/* <InputLabel id={`role-select-label-${index}`}>Status</InputLabel> */}
          <Select
            labelId={`role-select-label-${index}`}
            id={`role-select-${index}`}
            value={party.role || "VIEW"} // Default value to prevent undefined
            label="Status"
            onChange={(e) => {
              const newParties = [...parties];
              newParties[index].role = e.target.value;
              setParties(newParties);
            }}
            sx={{ borderRadius: "12px",width:"309px" }}
          >
            <MenuItem value="VIEW">VIEW</MenuItem>
            <MenuItem value="EDIT">EDIT</MenuItem>
          </Select>
              </div>
      </div>
   
  </div>
))}
  </ol>

          <div className={styles.containbuttons}>
          <Button className={styles.addDirector} onClick={handleAddParty}>
            <img
              className={styles.boxArrowInDownIcon}
              alt=""
              src="/boxarrowindown1.svg"
            />
            <img
              className={styles.arrowNarrowLeftIcon}
              alt=""
              src="/pluscircle1.svg"
            />
            <b className={styles.addDirector1}>Add Parties</b>
          </Button>
          <div className={styles.generateRequest} onClick={()=>{
            handleContinue()
            }}>
          {clicked &&<Lottie
          className={styles.httpslottiefilescomanimatIcon}
          animationData={load}
          loop={true}
        />}
            <b className={styles.generateRequest1}>Continue</b>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewContract.propTypes = {
  className: PropTypes.string,
};

export default NewContract;
