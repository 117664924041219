import PropTypes from "prop-types";
import styles from "./DirectkycDetail.module.css";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import DirectApprove from "./DirectorPopupConfirmation/DIrectorApprove"
import DirectReject from "./DirectorPopupConfirmation/DirectorRejected"
import { useLocation, useNavigate, useParams } from "react-router";
import ReactPlayer from "react-player"
import axios from "axios";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";

  const DirectkycDetail = ({ className = "" }) => {
    const [data,setData] =useState([])
    const location =useLocation()
    const navigate=useNavigate()
    const [showApprove,setShowApprove] =useState(false)
    const [showReject,setShowReject] =useState(false)
    const [isRejectVisible, setisRejectVisible]=useState(false)
    
    const onclose=()=>{
      setShowApprove(false)
      setShowReject(false)
    }
    const { id } = useParams()
    const compId=companyStore.companyID
    const handlenavi=()=>{
      navigate(`/content/company/kyclist/main`)
      }
      const handleScrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Optional: smooth scrolling
        });
      };
      useEffect(()=>{
        console.log("Director ID",companyStore.Dirct_KYC_id);
        
        let obj={
          kyc_id: companyStore.Dirct_KYC_id
        }
      axios.post(`${config.node_api_endpoint}/v1/director/kyc_request/details_demo_fe`,obj)
      .then((res)=>{
        console.log("response",res.data);
        setData(res.data)
        if(res.data.status=="pending_approval"){
          setisRejectVisible(true)
        }
      })
      },[])
      const handleDownloadAadhar = (uri,name) => {
        window.open(uri, '_blank').focus();
      }



      const handleDownloadPan = (newlink) => {
        window.open(newlink, '_blank').focus();
      }
      return (
    <Fragment>
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.frameParent}>
        
        <div className={styles.arrowNarrowLeftParent} onClick={()=>{handlenavi()}}>
          <img
            className={styles.arrowNarrowLeftIcon}
            alt=""
            src="/arrownarrowleft.svg"
          />
          <div className={styles.back}>Back</div>
        </div>

        <div className={styles.frameGroup}>
          <div className={styles.directorKycDetailsParent}>
            <div className={styles.directorKycDetails}>
              Director Kyc Details
            </div>
            <div className={styles.frameWrapper}>
              <div className={styles.frameContainer}>
                <div className={styles.directorNameParent}>
                  <div className={styles.directorName}>Director Name</div>
                  <div className={styles.directorName}>Director Email ID</div>
                  <div className={styles.directorName}>Remark</div>
                </div>
                <div className={styles.parent}>
                  {/* <div className={styles.div}>:</div> */}
                  {/* <div className={styles.div}>:</div> */}
                </div>
                <div className={styles.directorNameParent}>

                  <div className={styles.back}>{data?.director_name || "NA"}</div>
                  <div className={styles.back}>{data?.director_email|| "Not Provided"}</div>
                  <div className={styles.back}>{data?.director_remark|| "Not Provided"}</div>

                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.digilockerDataWrapper}>
              <b className={styles.digilockerData}>{`Digilocker Data `}</b>
            </div>
            <div className={styles.frameParent1}>
              
              
          {!isRejectVisible&& data?.status==="rejected"&&
            <div className={styles.status}>
              <div className={styles.statusHeading}>{data?.status?.toUpperCase()}</div>
              <div>{data.reason}</div>
              </div>
            }
              { !isRejectVisible&& data.status==="approved" &&<div className={styles.statusapproved}>
              <div className={styles.statusHeading}>{data?.status?.toUpperCase()}</div>
              <div>{data.reason}</div>
              </div>}
              <div className={styles.frameWrapper1}>
                <div className={styles.frameParent2}>
                  <div className={styles.frameParent3}>
                    <div className={styles.frameParent4}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div
                            className={styles.directorName}
                          >{`Id Number `}</div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>

                      <div className={styles.back}>{data?.kyc_details?.id || "NA"}</div>

                    </div>
                    <div className={styles.frameParent4}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div className={styles.directorName}>
                            Document Type
                          </div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>

                      <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.aadhaar?.document_type|| "NA"}</div>

                    </div>
                    <div className={styles.frameParent4}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div className={styles.directorName}>
                            Id Proof Type
                          </div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>
                      <div
                        className={styles.back}
                      >{data?.kyc_details?.actions[0]?.details?.aadhaar?.id_proof_type|| "NA"}</div>
                    </div>
                    <div className={styles.frameParent4}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div className={styles.directorName}>Gender</div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>
                      <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.aadhaar?.gender|| "NA"}</div>
                    </div>
                    <div className={styles.frameParent4}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div className={styles.directorName}>Name</div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>
                      <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.aadhaar?.name|| "NA"}</div>
                    </div>
                    <div className={styles.frameParent4}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div className={styles.directorName}>DOB</div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>
                      <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.aadhaar?.dob|| "NA"}</div>
                    </div>
                    <div className={styles.frameParent16}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div className={styles.directorName}>
                            Current Address
                          </div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>
                      <div
                        className={styles.soVijayan6rajajiStreetb}
                      >{data?.kyc_details?.actions[0]?.details?.aadhaar?.current_address|| "NA"}</div>
                    </div>
                    <div className={styles.frameParent16}>
                      <div className={styles.frameParent4}>
                        <div className={styles.idNumberWrapper}>
                          <div className={styles.directorName}>
                            Permanent Address
                          </div>
                        </div>
                        <div className={styles.div2}>:</div>
                      </div>
                      <div
                        className={styles.soVijayan6rajajiStreetb}
                      >{data?.kyc_details?.actions[0]?.details?.aadhaar?.permanent_address|| "NA"}</div>
                    </div>
                  </div>
                  <div className={styles.eAadhaarParent}>
                    <div className={styles.directorKycDetails}>e-Aadhaar</div>
                  {data?.kyc_details?.actions[0]?.details?.aadhaar?.image &&  <img alt={"Aadhaar"} className={styles.aadhaar_img} src={`data:image/png;base64,${data.kyc_details.actions[0].details.aadhaar.image}` }></img>}
                    <div className={styles.download} onClick={()=>{handleDownloadAadhar(data?.kyc_details?.aadhaar_link||"",data?.kyc_details?.actions[0]?.details?.aadhaar?.name|| "NA")}}>Download</div>
                  </div>
                  
                </div>
              </div>
              <div className={styles.frameParent20}>
                <div className={styles.frameParent3}>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameParent4}>
                      <div className={styles.idNumberWrapper}>
                        <div
                          className={styles.directorName}
                        >{`Id Number `}</div>
                      </div>
                      <div className={styles.div2}>:</div>
                    </div>
                    <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.pan?.id_number|| "NA"}</div>
                  </div>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameParent4}>
                      <div className={styles.idNumberWrapper}>
                        <div className={styles.directorName}>Document Type</div>
                      </div>
                      <div className={styles.div2}>:</div>
                    </div>
                    <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.pan?.document_type.toUpperCase()|| "NA"}</div>
                  </div>

                  {/* <div className={styles.frameParent4}>
                    <div className={styles.frameParent4}>
                      <div className={styles.idNumberWrapper}>
                        <div className={styles.directorName}>Id Proof Type</div>
                      </div>
                      <div className={styles.div2}>:</div>
                    </div>
                    <div className={styles.back}>{data?.KYCTYpe|| "NA"}</div>
                  </div> */}

                  <div className={styles.frameParent4}>
                    <div className={styles.frameParent4}>
                      <div className={styles.idNumberWrapper}>
                        <div className={styles.directorName}>Gender</div>
                      </div>
                      <div className={styles.div2}>:</div>
                    </div>
                    <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.pan?.gender|| "NA"}</div>
                  </div>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameParent4}>
                      <div className={styles.idNumberWrapper}>
                        <div className={styles.directorName}>Name</div>
                      </div>
                      <div className={styles.div2}>:</div>
                    </div>
                    <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.pan?.name|| "NA"}</div>
                  </div>
                  <div className={styles.frameParent4}>
                    <div className={styles.frameParent4}>
                      <div className={styles.idNumberWrapper}>
                        <div className={styles.directorName}>DOB</div>
                      </div>
                      <div className={styles.div2}>:</div>
                    </div>
                    <div className={styles.back}>{data?.kyc_details?.actions[0]?.details?.pan?.dob|| "NA"}</div>
                  </div>
                </div>
                <div className={styles.panParent}>
                  <div className={styles.directorKycDetails}>PAN</div>
                  
                  <button className={styles.download} src="" onClick={()=>{handleDownloadPan(data?.kyc_details?.pan_link ||"")}}>Download</button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.frameDiv}>
            <div className={styles.videoWrapper}>
              <b className={styles.digilockerData}>Video</b>
              {/* <b className={styles.digilockerData1}>Photo as per Aadhar</b> */}
            </div>

            <div>
              {`OTP: ${data?.kyc_details?.actions?.[1]?.otp}`} 
            </div>
            <div className={styles.digilockerdatavideoAadhar}>
              <div className={styles.videodatadigiio}>
            {
                data?.kyc_details?.video_link && (
    <>
    <video controls width="250" >
              <source src={data?.kyc_details?.video_link || ""} type="video/mp4" />
              Download the
              <a href={data?.kyc_details?.video_link}>MP4</a>
            </video>
            
    </>

  )
}
</div>
</div>
          </div>
          
          {isRejectVisible&& data.status=="pending_approval"&& 
          <div className={styles.buttonParent} >
                <div className={styles.reject} onClick={()=>{
                  setShowApprove(false)
                  handleScrollToTop()
                  setShowReject(true)}}>
                  <b className={styles.digilockerData} >Reject</b>
                </div>
                <div className={styles.approve} onClick={()=>{
                  setShowReject(false)
                  handleScrollToTop()
                  setShowApprove(true)}}>
                  <b className={styles.digilockerData} >Approve</b>
                </div>

              </div>}
        </div>
      </div>
    </div>
    
    <DirectApprove isVisible={showApprove} onclose={onclose}/>
    <DirectReject isVisible={showReject} onclose={onclose}/>
    </Fragment>
  );
};

DirectkycDetail.propTypes = {
  className: PropTypes.string,
};

export default DirectkycDetail;
