import PropTypes from "prop-types";
import styles from "./DIrectorApprove.module.css";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
const DIrectorApprove = ({ isVisible,onclose,  className = "" }) => {
  const navigate=useNavigate()
  const {id}=useParams()
  const kycid=companyStore.Dirct_KYC_id
  const compid =companyStore.companyID
  const handleApprove=()=>{
    let obj={
      kyc_id:kycid,
      status:"approved"
    }
    axios.post(`${config.node_api_endpoint}/v1/director/kyc_request/update_status_demo_fe`,obj)
    .then(()=>{
      console.log("status approved");
      navigate(`/content/company/route`)
      onclose()
    })
    .catch((er)=>{
      console.log(er)
      onclose()
    })
  }
  if(!isVisible) return null
  return (
    <div className={[styles.addCompany, className].join(" ")}>
      
      <div className={styles.areYouSure}>
        Are you sure you want to approve the actions?
      </div>
      <div className={styles.omg1xn3061}>
        <div className={styles.objects}>
          <div className={styles.group}>
            <div className={styles.group1}>
              <img className={styles.groupIcon} alt="" src="/group20.svg" />
              <img className={styles.groupIcon1} alt="" src="/group21.svg" />
              <img className={styles.groupIcon2} alt="" src="/group22.svg" />
              <img className={styles.groupIcon3} alt="" src="/group23.svg" />
            </div>
            <img className={styles.vectorIcon} alt="" src="/vector2.svg" />
            <img className={styles.groupIcon4} alt="" src="/group24.svg" />
          </div>
        </div>
      </div>
      <div className={styles.addCompanyInner}>
        <div className={styles.loadsButtonsParent}>
          <div className={styles.loadsButtons} onClick={onclose}>
            <b className={styles.loads} >No</b>
          </div>
          <div className={styles.loadsButtons1} onClick={()=>{
            handleApprove()}}>
            <b className={styles.loads} >Yes</b>
          </div>
        </div>
      </div>
    </div>
  );
};

DIrectorApprove.propTypes = {
  className: PropTypes.string,
};

export default DIrectorApprove;
