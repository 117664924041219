import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router'
import PageContainerComp from "./kyc/PageContainerComp"
import CompanyKycDetail from './CompanyKYCStatus/CompanyKycDetail';
import DirectkycDetail from './DirectorKYCStatus/DirectkycDetail';
import ApprovedPOPup from './approvalLottiPOPup/ApprovalPOPup';
import DirectorKYC from './DirectorKYC/DirectorKYC';
import CompanyKYC from './CompanyKYC/CompanyKYC';
import RequestGenerated from './RequestGenerated/RequestGenerated';
import RejectedKycDetail from "./RejectedKYCDetail/RejectedKycDetail"
import CompanyKYCRequestGenerated from "./CompanyKYCrequestgenerated/CompanyKYCRequestGenerated"
import CompanyContainer from "./CompanyList/CompanyContainer"
export default function KYCList() {
    const navigate=useNavigate()
    const location = useLocation();
    useEffect(()=>{
      if(location.pathname==="/content/company"){
        navigate('kyclist/company')
      }
        
    },[])
  return (
        <Routes>
        <Route path ="kyclist/company" element={<CompanyContainer />}/>          
        <Route path ="kyclist/main" element={<PageContainerComp />}/>
        <Route path ="compkyc/:id" element={<CompanyKycDetail />}/>
        <Route path ="directkyc/detail" element={<DirectkycDetail/>}/>
        <Route path ='companykyc/detail' element={<CompanyKycDetail/>}/>
        <Route path ='route' element={<ApprovedPOPup/>}/>
        <Route path ='reject' element={<RejectedKycDetail/>}/>
        <Route path ='requestsend' element={<RequestGenerated/>}/>
        <Route path ='director' element={<DirectorKYC/>}/>
        <Route path ='newcompanykyc' element={<CompanyKYC/>}/>
        <Route path ='rejectedkyc' element={<RejectedKycDetail/>}/>
        <Route path ='kyc_request' element={<CompanyKYCRequestGenerated/>}/>

        </Routes>
  )
}
