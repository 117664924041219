import { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import styles from "./DirectorKYC.module.css";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";

const DirectorKYC = ({ className = "" }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [directors, setDirectors] = useState([{ uname: "", email: "" ,remark:""}]);
  const [len, setLen] = useState(1);
  const [remark, setRemake]=useState("")
  const [compName,setCompName]=useState("")
  const [id,setId]=useState("")
  useEffect(()=>{
    setId(companyStore.companyID);
    console.log("in director new Director",companyStore.companyID);
    setCompName(companyStore.companyName)
    
  },[])
  // useEffect(() => {
  //   axios.get(`http://localhost:5050/v1/director?company_id=${id}`)
  //     .then((res) => {
  //       console.log(res.data);
        
  //       setData(res.data);
  //       setLen(res.data.KYC.length + 1);
  //     })
  //     .catch(() => {
  //       console.log("Try Again");
  //     });
  // }, [id]);

  const onFrameButtonClick = useCallback(() => {
    navigate(`/content/company/kyclist/main`);
  }, [navigate, id]);

  const onFrameButtonClick1 = useCallback(() => {

    if (directors.some(({ uname, email }) => uname === "" || email === "")) {
      alert("All fields are required");
      return;
    }
handleScrollToTop()
    const kycData = directors.map(({ uname, email, remark }, index) => ({
      name: uname,
      email: email,
      remark:remark
    }));
  
    const { NameOfCompany, created_date, status, POCName, KYC } = data;
    const comp_info = {
      company_id:id,
      director_list: [ ...kycData]
    };
    console.log("Company info",comp_info);
    
    axios.post(`${config.node_api_endpoint}/v1/director/kyc_request`, comp_info)
      .then(() => {
        console.log("Data updated");
        navigate(`/content/company/requestsend`)
      })
      .catch(() => {
        console.log("Try Again");
      });
      
  }, [directors,  data, len, id, navigate]);

  const handleInputChange = (index, field, value) => {
    const newDirectors = [...directors];
    newDirectors[index][field] = value;
    setDirectors(newDirectors);
  };

  const addDirector = () => {
    let newDirectors = {};
    setDirectors([...directors, { uname: "", email: "" }]);
  };

  const clearDirectorFields = (index) => {
    const newDirectors = [...directors];
    newDirectors[index] = { uname: "", email: "" };
    setDirectors(newDirectors);
  };

  const removeDirector = (index) => {
    const newDirectors = directors.filter((_, i) => i !== index);
    setDirectors(newDirectors);
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: smooth scrolling
    });
  };
  return (
    <div className={[styles.pageContainer, className].join(" ")}>
      <div className={styles.frameParent}>
        <button
          className={styles.arrowNarrowLeftParent}
          onClick={onFrameButtonClick}
        >
          <img
            className={styles.arrowNarrowLeftIcon}
            alt=""
            src="/arrownarrowleft2.svg"
          />
          <div className={styles.back}>Back</div>
        </button>
        <b className={styles.escrowstackPrivateLimited}>
          {compName}
        </b>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.frameWrapper}>
              <div className={styles.directorKycParent}>
                <div className={styles.directorKyc}>
                  <ol className={styles.directorKycList}>
                    {directors.map((director, index) => (
                      <div key={index} className={styles.frameDiv}>
                        <div className={styles.framehead}>
                          <li>Director KYC</li>
                          <div className={styles.buttons}>
                          <button
                            className={styles.input}
                            onClick={() => clearDirectorFields(index)}
                          >
                            Clear
                          </button>

                          {index > 0 && (
                               <button
                                  className={styles.remove}
                                   onClick={() => removeDirector(index)}
                                    >
                                        REMOVE
                                          </button>
                                  )}  

                          </div>
                        </div>
                        <div className={styles.mobileMenuParent}>
                          <TextField
                            className={styles.mobileMenu}
                            color="primary"
                            label={`Director Name ${index + 1}`}
                            variant="outlined"
                            value={director.uname}
                            onChange={(e) => handleInputChange(index, "uname", e.target.value)}
                            sx={{ "& .MuiInputBase-root": { height: "56px" } }}
                          />
                          <div className={styles.mobileMenu1}>
                            <TextField
                              className={styles.field}
                              color="primary"
                              label={`Director Email ID ${index + 1}`}
                              variant="outlined"
                              type="email"
                              value={director.email}
                              onChange={(e) => handleInputChange(index, "email", e.target.value)}
                              sx={{
                                "& .MuiInputBase-root": { height: "56px" },
                                width: "344px",
                                fontSize: "16px"
                              }}
                            />
                          </div>
                        </div>
                        <textarea className={styles.mobileMenu2} placeholder="Remark" value={director.remark} onChange={(e)=>{handleInputChange(index, "remark", e.target.value)}}/>
                      </div>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.loadsButtonsParent}>
            <button
              className={styles.loadsButtons}
              onClick={()=>{addDirector()}}
            >
              <img
                className={styles.arrowNarrowLeftIcon}
                alt=""
                src="/pluscircle3.svg"
              />
              <b className={styles.addDirector}>Add Director</b>
            </button>
            <button

              className={styles.loadsButtonsWrapper}
              onClick={onFrameButtonClick1}
            >
              <div className={styles.loadsButtons1}>
                <b className={styles.generateRequest}>Generate Request</b>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DirectorKYC.propTypes = {
  className: PropTypes.string,
};

export default DirectorKYC;
