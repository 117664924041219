import PropTypes from "prop-types";
import styles from "./EsignMain.module.css"
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../service/config"
import DeleteEsignRequest from "./DeleteEsignRequest/DeleteEsignRequest";
import {
  Pagination,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import companyStore from "../CompanyStore";

const EsignMain = ({ className = "" }) => {
  const [data, setData] = useState([])
  const [arr, setArr] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [showModel, setShowModel] = useState(false)
  const [compName, setCompName] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [docId, setdocId] = useState("")
  const [id, setID] = useState("")

  useEffect(() => {
    console.log("esign main");
    setID(companyStore.companyID)
    console.log("company id=", id);
    setCompName(companyStore.escrowName);

    let payload = {
      escrow_id: companyStore.escrowId,
    };

    axios.post(`${config.node_api_endpoint}/v2/esign/listdocuments`, payload)
      .then((res) => {
        setData(res.data)
        setFilteredData(res.data)
        console.log("esign doc id List Document", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate()

  const resetFilters = () => {
    setFilteredData(data);
  }

  const applyFilters = () => {
    let filtered = data;

    if (statusFilter) {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => new Date(item.created_at).toDateString() === new Date(createdDateFilter).toDateString());
    }

    if (textFilter) {
      filtered = filtered.filter((item) => item.status === textFilter);
    }

    setFilteredData(filtered);
  };
  return (
    <Fragment>
      <div className={[styles.pageContainer, className].join(" ")}>

        <div className={styles.frameParent}>
          <div className={styles.escrowstackPrivateLimitedParent}>
            <b className={styles.escrowstackPrivateLimited}>
              {compName}</b>
            <button className={styles.loadsButtons} onClick={() => { navigate(`/content/esign/createesign`); }}>
              <img
                className={styles.plusCircleIcon}
                alt=""
                src="/pluscircle2.svg"
              />
              <b className={styles.newKycRequest}>New E-sign Request</b>
            </button>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              {/* Filter inputs */}
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <img className={styles.leadingIcon} alt="" src="/leadingicon3.svg" />
                  <TextField label="Search" variant="standard" value={textFilter} onChange={(e) => { setTextFilter(e.target.value) }} />
                </div>
              </div>

              <div className={styles.mobileMenuParent}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
                  />
                </LocalizationProvider>

                <FormControl sx={{ width: "250px" }}>
                  <InputLabel id="escrow-select-label">Status</InputLabel>
                  <Select
                    labelId="escrow-select-label"
                    id="escrow-select"
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => setStatusFilter(e.target.value)}
                    sx={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"draft"}>Draft</MenuItem>
                    <MenuItem value={"requested"}>Requested</MenuItem>
                    <MenuItem value={"completed"}>Completed</MenuItem>
                    <MenuItem value={"expired"}>Expired</MenuItem>
                  </Select>
                </FormControl>

                {/* Button to apply filter */}
                <div className={styles.loadsButtons1} onClick={applyFilters}>
                  <b className={styles.escrowstackPrivateLimited}>Apply</b>
                </div>
              </div>
            </div>
          </div>

          <TableContainer component={Paper} className={styles.table_div}>
            <Table>
              <TableHead className={styles.theadcontain}>
                <TableRow className={styles.trheaderContent}>
                  <TableCell className={styles.tableHeading}>Name Of Aggrement</TableCell>
                  <TableCell className={styles.tableHeading}>Last Updated</TableCell>
                  <TableCell className={styles.tableHeading}>Status</TableCell>
                  <TableCell className={styles.tableHeading}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={styles.tbodycontain}>
                {filteredData.map((detail, index) => (
                  <TableRow key={index}>
                    <TableCell>{detail.agreement_name}</TableCell>
                    <TableCell>{detail.created_at.split("T")[0]}</TableCell>
                    <TableCell>{detail.status?.toUpperCase() || ""}</TableCell>
                    <TableCell className={styles.containerbtn}>
                      <button className={styles.btn} /*view Request Button*/
                        onClick={() => {

                          setdocId(detail._id)
                          console.log("doccccccccid", detail._id);
                          companyStore.setDoc_id(detail._id)
                          navigate(`/content/esign/preview/doc`)
                        }}
                      //[0] is docid, [1] is company id
                      >
                        <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                      </button>
                      <b className={styles.b}>/</b>
                      <button className={styles.btn} /*Delete Request Button*/
                        onClick={() => {
                          setShowModel(true)
                          setdocId(detail._id)
                          console.log("docid", detail._id);
                          companyStore.setDoc_id(detail._id)
                        }}
                      >
                        <img className={styles.eyeIcon} alt="" src="/trash-01.svg" />
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>


        </div>
      </div>
      <DeleteEsignRequest isVisible={showModel} onclose={() => { setShowModel(false) }} Docid={docId} />
    </Fragment>
  );
};

EsignMain.propTypes = {
  className: PropTypes.string,
};

export default EsignMain;
