import { makeAutoObservable } from 'mobx';

class CompanyStore {
  escrowId = "";
  escrowName = "";
  companyName = '';
  companyID = '';
  KYBId = '';
  directorId = '';
  doc_id = '';
  Dirct_KYC_id = "";

  constructor() {
    // MobX makes the store observable and reactive
    makeAutoObservable(this);
    this.hydrate(); // Retrieve stored data on initialization
  }

  // Actions to update the fields
  setEscrowName(name) {
    this.escrowName = name;
    localStorage.setItem('EscrowName', name);
  };

  setEscrowId(id) {
    this.escrowId = id;
    localStorage.setItem('EscrowId', id);
  };

  setCompanyName(name) {
    this.companyName = name;
    localStorage.setItem('CompanyName', name);
  }

  setCompanyID(id) {
    this.companyID = id;
    localStorage.setItem('CompanyID', id);
  }

  setKYBId(id) {
    this.KYBId = id;
    localStorage.setItem('KYBId', id);
  }
  setDirect_KYC_ID(id) {
    this.Dirct_KYC_id = id;
    localStorage.setItem('Dirct_KYC_id', id);
  }

  setDirectorId(id) {
    this.directorId = id;
    localStorage.setItem('DirectorId', id);
  }

  setDoc_id(id) {
    this.doc_id = id;
    localStorage.setItem('Doc_id', id);
  }

  // Hydrate the store from localStorage when the app is initialized
  hydrate() {
    const storedEscrowName = localStorage.getItem('EscrowName');
    const storedEscrowId = localStorage.getItem('EscrowId');

    const storedCompanyName = localStorage.getItem('CompanyName');
    const storedCompanyID = localStorage.getItem('CompanyID');
    const storedKYBId = localStorage.getItem('KYBId');
    const storedDirectorId = localStorage.getItem('DirectorId');
    const storedDocId = localStorage.getItem('Doc_id');
    const storedDirectKYC_Id = localStorage.getItem('Dirct_KYC_id');

    if (storedEscrowName) this.escrowName = storedEscrowName;
    if (storedEscrowId) this.escrowId = storedEscrowId;
    if (storedCompanyName) this.companyName = storedCompanyName;
    if (storedCompanyID) this.companyID = storedCompanyID;
    if (storedKYBId) this.KYBId = storedKYBId;
    if (storedDirectorId) this.directorId = storedDirectorId;
    if (storedDocId) this.doc_id = storedDocId;
    if (storedDirectKYC_Id) this.Dirct_KYC_id = storedDirectKYC_Id;
  }
}

const companyStore = new CompanyStore();
export default companyStore;
